@import url("https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,400i,700,700i|Ubuntu:300,300i,400,400i,700,700i&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$font-family-sans-serif: "Ubuntu", sans-serif;
$font-family-monospace: "Ubuntu Mono", monospace;

$fa-font-path: "../static-npm/fontawesome";
$enable-print-styles: false;
$break-1300: 1300px;
$break-640: 640px;
$break-420: 420px;
$break-360: 360px;
$break-320: 320px;

@import "./variables.scss";
@import "node_modules/@coreui/coreui/scss/coreui.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

.custom-select {
  -webkit-appearance: none; // This is a fix for Chrome
}

footer a {
  // color: #9c4cd4;
  color: #250233;
  background-color: transparent;
}

.app-footer {
  justify-content: center;
}

.react-fine-uploader-gallery-file-input-container {
  width: 130px;
  svg {
    display: none;
  }
}
// Awesome slider
.aws-btn {
  --slider-height-percentage: 60%;
  --slider-transition-duration: 700ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #26456f;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #2d5182;
  --control-bullet-active-color: #26456f;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
}

.src-lib-sandboxed-grapesjs__navbar--2LwiG {
  background: #26072d !important;
}

.src-lib-sandboxed-grapesjs__title--_b477:before {
  background-image: url(https://www.prophetelvis.com/assets/img/logo.png);
  content: "Prophet's ";
  background-size: contain;
  background-repeat: no-repeat;
  color: transparent;
  margin-right: 1em;
}
//Header
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7) !important;
}

.navbar-dark .navbar-nav .active > .nav-link {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 500;
}
//Login form
.login-page {
  width: 600px;
  // padding: 6% 0 0;
  padding: 3% 0 0;
  margin: auto;
  @media screen and (max-width: $break-640) {
    max-width: 340px;
  }
}
.login-page .col-sm-2 {
  display: none !important;
}

.login-page .col-sm-10 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.src-lib-styles__checkboxText--2YD6q {
  // float: left;
  display: inline;
  padding-left: 5%;
}

.login-page label {
  margin-bottom: 0px !important;
}

.login-page .form-group {
  margin-bottom: 0px !important;
}

.login-page .btn {
  margin-bottom: 1rem;
}

.login-page .h4 {
  margin-bottom: 2rem !important;
}

.login-page .form-check {
  padding-left: 0.25rem !important;
  text-align: left !important;
}

.login-page .form-check-input {
  margin-bottom: 0rem;
  // width: 86%;
  margin-top: 7px;
  background: transparent;
  width: 5%;
}

.login-page .form input {
  font-size: 16px !important;
}

.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 640px;
  margin: 0 auto 100px;
  padding: 15px 30px 30px 30px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  @media screen and (max-width: $break-640) {
    max-width: 340px;
  }
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 25px 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4caf50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,
.form button:active,
.form button:focus {
  background: #43a047;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #4caf50;
  text-decoration: none;
}

.login-page form h4 {
  margin-bottom: 1.2rem !important;
}
//End Login form

//Home page css

.card-h {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  // background-color: #fff;
  background-color: #26072d !important;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  // border-radius: 0.25rem;
  border-radius: 2rem 2rem 0 0 !important;
  overflow: hidden;
}

.card-img-top {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  background: #26072d;
}

.card-h img {
  max-width: 67%;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem 0 0 0 !important;
  // padding-bottom: 0.75rem;
  background: white;
}

.card-title {
  margin-bottom: 0.75rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.card-text {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.home-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  // margin-top: 3%;
  margin-top: 1.7%;
}

.card-body .btn-sm {
  padding: 0.55rem 0.5rem !important;
  border-radius: 0rem !important;
  width: 100%;
}

//Youtube
.ytp-play-progress {
  background: #b31aff !important;
}

.ytp-large-play-button.ytp-touch-device .ytp-large-play-button-bg,
.ytp-cued-thumbnail-overlay:hover .ytp-large-play-button-bg,
.ytp-muted-autoplay-endscreen-overlay:hover .ytp-large-play-button-bg {
  -moz-transition: fill 0.1s cubic-bezier(0, 0, 0.2, 1),
    fill-opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transition: fill 0.1s cubic-bezier(0, 0, 0.2, 1),
    fill-opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
  transition: fill 0.1s cubic-bezier(0, 0, 0.2, 1),
    fill-opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
  fill: #b31aff !important;
  fill-opacity: 1;
}

@media (min-width: 1600px) {
  .home-container {
    max-width: 1200px;
  }
  .topbar {
    margin-left: 14.2em;
  }
}

@media (min-width: 1450px) and (max-width: 1599px) {
  .home-container {
    max-width: 1140px;
  }
  .topbar {
    // margin-left: 13.5em;
    // margin-left: 0em;
    padding: 0 0 1em 2em;
    border-bottom: 1px solid #aaa;
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 1400px) and (max-width: 1450px) {
  .home-container {
    max-width: 1140px;
  }
  .topbar {
    margin-left: 10.6em;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .topbar {
    margin-left: 8.2em;
  }
  .home-container {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .topbar {
    margin-left: 5.2em;
  }
  .home-container {
    max-width: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .home-container {
    max-width: 960px;
  }
  .topbar {
    margin-left: 2.2em;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .topbar {
    margin-left: 1.2em;
  }
}

//End Home page css

.src-lib-sandboxed-grapesjs__navbarLeft--1KQfz
  .src-lib-sandboxed-grapesjs__logo--3Gf99 {
  display: none !important;
}

body.mailtrain {
  background-color: white;
  .article {
    text-align: left;
    padding: 0 0.7em;
  }
  .article > div:first-of-type > div:first-of-type {
    //max-height:60em;
    height: auto;
    // overflow-y: auto; //Commented out to prevent default scrollbar
    margin: 1em 0;
  }
  .centered-div > div:first-of-type > div:first-of-type hr {
    display: none;
  }
  .centered-div h2 {
    margin-bottom: 1rem;
  }
  .changeAvatar > div:first-of-type > div:first-of-type {
    margin: 0 auto;
  }
  .changeAvatar > a {
    padding: 0.6em 1.1em;
    background: purple;
    border-radius: 5px;
    color: white;
  }
  .reply-block {
    margin-left: 4.5em;
  }
  .ahead {
    font-size: 21px;
    font-weight: bolder;
    line-height: inherit;
  }
  .displaystat {
    display: inline;
    @media screen and (max-width: $break-420) {
      display: none;
    }
  }
  .stats-row {
    text-align: center;
    color: white;
    margin-left: 0.5em;
    @media screen and (max-width: $break-420) {
      text-align: left;
    }
  }
  .stats-row a {
    color: white !important;
  }
  .stats-row .col-md-3 {
    margin-bottom: 8px;
  }
  .stats-row .col-sm {
    width: auto !important;
  }
  .article-share__icons {
    display: inline;
    margin-left: 0.4em;
  }
  .article-share__icons button {
    margin-right: 0.3em;
    @media screen and (max-width: $break-420) {
      width: 16px;
      margin-right: 1.5em;
    }
  }

  // Temp FB Video CSS
  ._4s0y {
    height: 0;
    width: 100%;
    padding-bottom: 58.906% !important;
  }
  // End Temp
  .comments-box {
    background: white;
    padding: 0.4em 0em 0.4em 0.4em;
    margin-bottom: 0.3em;
    border-radius: 3px;
    background: #2b002a;
  }
  .css-mxjn5s {
    background-color: rgb(255, 255, 255);
    // box-shadow: rgba(100, 100, 100, 0.6) 0px 0px 0px 1px;
    border-radius: 3px;
    padding: 6px;
    margin-right: 5px;
    display: flex;
    background: transparent;
    border-bottom: 1px solid rgba(100, 100, 100, 0.6);
    padding-bottom: 0.7em;
  }
  .css-mxjn5s__reply {
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
    margin-top: 8px;
    border-radius: 3px;
    padding: 8px;
    margin-right: 5px;
    display: flex;
    background: transparent;
  }
  .css-mxjn5s__avatar {
    display: inline-block;
    width: 45px;
    height: 45px;
    margin-right: 10px;
    background-size: contain;
    border-radius: 50%;
  }
  .css-mxjn5s__col-right {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 45px);
    -webkit-box-align: center;
    align-items: center;
  }
  .css-mxjn5s__name {
    color: rgb(66, 82, 110);
    color: #eee !important;
    font-weight: 600;
    font-size: 14px !important;
    margin-right: 10px;
    font-family: "Roboto", sans-serif;
  }
  .css-mxjn5s__time {
    font-size: 13px;
    color: rgb(164, 184, 201);
  }
  .css-mxjn5s__content {
    color: rgb(23, 43, 77);
    color: #eee !important;
    margin-top: 4px;
    font-weight: 400;
    font-size: 13px !important;
    flex: 1 1 100%;
    font-family: "Roboto", sans-serif;
    @media screen and (max-width: $break-420) {
      font-size: 11px !important;
    }
  }
  .css-oot9fd,
  .load-more {
    display: inline-block;
    font-family: inherit;
    line-height: 1.2;
    text-align: center;
    cursor: pointer;
    color: rgb(255, 255, 255);
    padding: 0.45em 1em;
    border-width: 1px;
    border-style: solid;
    border-color: #aaa;
    border-image: initial;
    border-radius: 0.5em;
    text-decoration: none;
    outline: none;
    background: #26072d !important;
    font-family: "Ubuntu";
    font-size: 0.8em;
    margin-left: 0.3em;
  }
  .css-u55gqp {
    width: 99.5%;
    //width: 85%;
    float: right;
    font-size: 1em;
    height: 2.2em;
    overflow: hidden;
    //color: #fff;
    color: #111;
    resize: none;
    margin-bottom: 10px;
    margin-top: 0.5em;
    box-sizing: border-box;
    border-width: initial;
    border: 0px solid #eee;
    border-color: initial;
    padding: 15px;
    border-radius: 7px;
    outline: none;
    //background: transparent!important;
    background: #f5f5f5 !important;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #111;
    opacity: 1; /* Firefox */
    font-size: 0.9em !important;
  }
  .toggle-reply {
    float: right;
    margin: auto 0;
    margin-right: 0.5em;
    color: #20a8d8;
    width: 16%;
    font-size: 7px;
    border: 1px solid;
    border-radius: 2px;
    padding: 3px;
    text-align: center;
    background-color: #2b002a;
    color: #ccc;
    border: none;
    cursor: pointer;
    font-family: "Ubuntu";
    @media screen and (min-width: $break-420) {
      font-size: 11px;
      background-color: #2b002a !important;
      color: #ccc !important;
      border: none !important;
      cursor: pointer;
      font-family: "Ubuntu";
    }
  }
  .repliesno {
    margin-top: 0.6em;
    margin-bottom: 0.5rem;
    font-size: 0.9em;
    margin-left: 0.1em;
  }
  .article::-webkit-scrollbar {
    display: none;
  }
  hr {
    border-top-color: beige;
    opacity: 0.5;
    width: 93%;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    max-height: 60px !important;
  }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 30px;
  }
  .image-gallery {
    background: #222222;
  }
  @media screen and (max-width: $break-420) {
    #social-c2882id {
      max-width: 23em;
    }
  }
  .wrapper-div {
    // background-color: #000; /* for visualization purposes */
    background-color: #f5f5f5; /* for visualization purposes */
    text-align: center;
    margin: -1rem -15px 0 -15px; /* for social page, as we couldn't edit the "main" tag */
    padding-top: 1.5rem;

    .centered-div {
      // background-color: #f2f2f2; /* for visualization purposes */
      display: inline-block;
      padding: 0.1em;
      /* width: 50em; */
      width: 44em;
      overflow-x: hidden;
      @media screen and (max-width: $break-420) {
        width: 25em;
        padding: 1em;
        padding-top: 0.1em;
      }
      @media screen and (max-width: $break-360) {
        width: 24em;
        padding: 1em;
        padding-top: 0.1em;
      }
      @media screen and (max-width: $break-320) {
        width: 22em;
        padding: 1em;
        padding-top: 0.1em;
      }
    }
  }

  .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    // background-color: #fff;
    // background-color: #26072d !important;
    background-color: #000 !important;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    overflow: hidden;
    height: auto !important;
  }

  table .container {
    max-width: unset !important;
  }

  .btn-black {
    color: #fff;
    background-color: #111;
    border-color: #111;
    text-transform: capitalize;
  }

  .btn-primary {
    color: #fff;
    background-color: #250233;
    border-color: #250233;
    text-transform: capitalize;
    margin-top: 10px;
  }

  .btn-secondary {
    margin-top: 10px;
  }

  .card-body .btn-sm {
    color: #fff;
    background-color: #111;
    border-color: #111;
    text-transform: capitalize;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #9c4cd4;
    border-color: #9c4cd4;
  }

  &.sandbox {
    overflow-x: hidden;
  }

  &.inside-iframe {
    overflow: hidden;
  }

  .sandbox-loading-message {
    // The 40 px below corresponds to the height in in UntrustedContentHost.render
    height: 40px;
  }

  .dropdown-item {
    border-bottom: none 0px;
  }

  .mt-breadcrumb-and-tertiary-navbar {
    // display: flex;
    display: none;
    border-radius: 0;
    @include border-radius($breadcrumb-border-radius);
    @include borders($breadcrumb-borders);
    background-color: $breadcrumb-bg;

    a {
      // color: #9c4cd4;
      color: #250233;
      background-color: transparent;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #fff;
      background-color: #9c4cd4;
    }

    .breadcrumb {
      border: 0px none;
      margin-bottom: 0px;
      padding-top: 11px;
      padding-bottom: 12px;
    }

    margin-bottom: 1rem;
  }

  @include media-breakpoint-down(md) {
    .app-header {
      position: relative;
    }

    .app-body {
      margin-top: 0px;
    }
  }

  .app-header {
    .navbar {
      padding: 0 15px;
      background-color: #250233 !important;

      .dropdown-item.active,
      .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: #9c4cd4;
      }

      .navbar-brand {
        // width: $sidebar-width - 30px;
        width: 130px; // We don't use sidebar yet, so no need to align with it.

        justify-content: left;

        @include media-breakpoint-down(md) {
          // This is to keep the title and the menu buttons in order. Normally the brand is position: absolute which distorts the order
          position: relative;
          top: auto;
          left: auto;
          margin: 0px;
        }

        img {
          margin-top: 0.4rem;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .navbar-toggler {
        color: #fff;
        height: 35px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .navbar-toggler {
      .navbar-toggler-icon,
      &:hover .navbar-toggler-icon {
        background-image: $navbar-dark-toggler-icon-bg;
      }
    }

    .navbar-nav {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .mt-navbar-nav-right {
      @include media-breakpoint-up(lg) {
        flex-grow: 1;
        justify-content: flex-end;
      }

      .nav-item {
        .nav-link {
          padding: 0px;
        }

        @include media-breakpoint-up(lg) {
          margin-left: 15px;
        }

        @include media-breakpoint-down(md) {
          margin-right: 15px;

          .dropdown-menu-right {
            right: auto;
            left: 0;
          }
        }
      }
    }

    .mt-navbar-nav-left {
      @include media-breakpoint-up(lg) {
        // margin-left: 15px;
        margin-left: 2.5em;
        flex-grow: 25;
        justify-content: center;
        @media screen and (max-width: $break-1300) {
          margin-left: 1em;
        }
      }

      .nav-item {
        // margin-right: 15px;
        margin-right: 1.5em;
        padding-left: 1.5em;
        border-left: 1px solid;
        @media screen and (max-width: $break-1300) {
          margin-right: 0.5em;
          padding-left: 0.5em;
        }
      }

      .nav-item:last-child {
        border-right: 1px solid;
        padding-right: 1.5em;
      }
    }

    .nav-item {
      min-width: 0px;
    }
  }

  .main .container-fluid {
    padding: 0 15px;

    @include media-breakpoint-down(md) {
      padding: 0 3px;
    }

    table a {
      color: #9c4cd4;
      background-color: transparent;
    }

    .page-item.active .page-link {
      z-index: 1;
      color: #fff;
      background-color: #9c4cd4;
      border-color: #9c4cd4;
    }

    .login-page .form-group a {
      color: #250233 !important;
    }

    .form-group a {
      color: #9c4cd4;
    }
  }

  .navbar-dark {
    .navbar-nav {
      .active > .nav-link:hover {
        color: $navbar-dark-active-color;
      }
    }
  }
}

.gpg-text {
  font-family: monospace;
}
